







import { Component } from 'vue-property-decorator'

import MasterOrdersTable from '@/components/tables/MasterOrdersTable.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import ManagerUsersModule from '@/store/modules/manager/users'

@Component({
  components: {
    MasterOrdersTable,
  },
})
export default class MastersItemPayments extends NotifyMixin {
  private get user () {
    return ManagerUsersModule.user
  }
}
